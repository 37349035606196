<template>
  <div class="px-4">
    <SubscribedCourseActivity />
  </div>
</template>

<script>
import SubscribedCourseActivity from "@/components/SubscribedCourseActivity.vue";
export default {
  name: "AcademicTab",
  components: { SubscribedCourseActivity },
};
</script>

<style lang="scss">
@import "@/style/academic-tab.scss";
</style>
